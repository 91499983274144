<template>
  <v-container class="h-600 w-500">
    <v-layout column>
      <template v-if="loading">
        <div class="d-flex align-center justify-center">
          <v-progress-circular size="200" color="primary" indeterminate />
        </div>
      </template>
      <template v-else>
        <v-flex class="padding-x-3 padding-b-3">
          <template v-if="transcript.length === 0">
            <div class="text-center">No chat transcript available</div>
          </template>
          <v-layout
            v-for="(chat, index) in transcript"
            :key="chat.id"
            class="margin-b-5 margin-l-3"
            row
          >
            <v-flex>
              <div
                v-if="showParticipantName(chat, index)"
                class="margin-b-2 line-height-12"
                :class="
                  chat.direction === 'Inbound'
                    ? 'text-primary'
                    : 'text-blue-dark'
                "
              >
                {{ getChatParticipant(chat.direction) }}
              </div>
              <div class="text-gray-medium-light">
                {{ createdAtDatetime(chat.createdAt) }}
              </div>
              <div>
                <pre class="white-space-pre-wrap">{{ chat.message }}</pre>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </template>
    </v-layout>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon'
import { mapActions } from 'vuex'
import chats from '@/services/chats'

export default {
  props: {
    chatId: { type: Number },
    chatFrom: { type: String },
    chatTo: { type: String },
    answeredByName: { type: String },
  },
  data() {
    return {
      loading: false,
      transcript: [],
    }
  },
  emits: ['close-form'],
  async mounted() {
    await this.getChatData()
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
      closeDialog: 'app/closeDialog',
    }),
    async getChatData() {
      try {
        this.loading = true
        const res = await chats.byId(this.chatId)
        this.transcript = res.data.data.chatTranscripts
        this.loading = false
      } catch (e) {
        this.loading = false
        console.error(e)
      }
    },
    createdAtDatetime(datetime) {
      return DateTime.fromISO(datetime).toFormat('MM/dd/yy · hh:mm a')
    },
    showParticipantName(chat, index) {
      if (index === 0) return true
      else if (this.transcript[index - 1].direction === chat.direction)
        return false
      return true
    },
    getChatParticipant(direction) {
      switch (direction) {
        case 'Inbound':
          return this.chatFrom
        case 'Outbound':
          return this.answeredByName
        default:
          return 'Unknown'
      }
    },
  },
}
</script>

<style lang="scss"></style>
